import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import { resetIdle } from '../idle'
import { MsjsProvider } from './contexts/msjsContext'  

const App = () => {

  useEffect(() => {
    const elements = document.querySelectorAll('button, input, select, textarea');
    elements?.forEach((element) => {
      element.addEventListener('click', resetIdle);
      element.addEventListener('change', resetIdle);
      element.addEventListener('keypress', resetIdle);
    });

    // Limpia el event listener al desmontar el componente App
    return () => {
      elements?.forEach((element) => {
        element.removeEventListener('click', resetIdle);
        element.removeEventListener('change', resetIdle);
        element.removeEventListener('keypress', resetIdle);
      });
    };
  }, []);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <MsjsProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </MsjsProvider>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
