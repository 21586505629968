import React, {useEffect, useState} from 'react'
import './SellStore.scss'
import axios from 'axios'
import {useAuth} from '../../../auth'
import {formatNumber} from '../../../../utilities/_format'
import {useAuthStore} from '../../../../store/authStore'

const API_URL = process.env.REACT_APP_API_URL
const GET_SALES_BY_BRANCH_URL = `${API_URL}/branch/business_partner_sales_by_date_range_per_branch`

const SellStore = () => {
  const [data, setData] = useState([])
  const [counter, setCounter] = useState(0)
  //const {currentUser} = useAuth()
  const {saveAuth, currentUser, userCan, userHas, userLandingRoute, auth, setCurrentUser, logout} =
    useAuthStore()
  const token = currentUser?.api_token

  useEffect(() => {
    if (token) {
      const endDate = new Date()
      const startDate = new Date(new Date().setDate(endDate.getDate() - 30))

      axios
        .get(GET_SALES_BY_BRANCH_URL, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: {
            business_partner_id: currentUser?.business_partner_id,
            start_date: startDate.toISOString().split('T')[0],
            end_date: endDate.toISOString().split('T')[0],
          },
        })
        .then((response) => {
          setData(response.data.data)
          setCounter(response.data.data?.length)
        })
        .catch((error) => console.error('Error al obtener datos de la API', error))
    }
  }, [token, currentUser])

  return (
    <div className='sellstore__card card'>
      <div className='overflow-auto m-10'>
        <h1 className='text-primary fs-2'>Ventas por tienda - Último Mes</h1>
        <div className='card-body'>
          {data
            .sort((a: any, b: any) => b.quantity_invoiced - a.quantity_invoiced)
            .map((sale: any, index: number) => (
              <div className='sellstore__item d-flex align-items-center mb-5' key={index}>
                <span className='bullet bullet-vertical h-40px'></span>
                <div className='sellstore__form form-check form-check-custom form-check-solid mx-4'>
                  <h1>{(index + 1).toString().padStart(2, '0')}</h1>
                </div>
                <div className='flex-grow-1 fw-bold fs-4'>
                  {sale.name}
                  <span className='fw-light d-block'>Total : {sale.quantity_invoiced}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default SellStore
