import create from 'zustand';
import { CurrencyFormat, currencyFormats } from '../utilities/currencyFormats';
import { MonedasType } from '../types';
type States = {
  currentCurrency: MonedasType;
};

type Actions = {
  changeCurrency: (currency: MonedasType) => void;
  getCurrencyFormats: CurrencyFormat|any
};
// Definir el store de Zustand
const useCurrencyStore = create<States & Actions>((set, get) => ({
  currentCurrency: 'VES',
  changeCurrency: (currency:MonedasType) => set({ currentCurrency: currency }),
  getCurrencyFormats: () => currencyFormats[get().currentCurrency],
}));

export default useCurrencyStore;