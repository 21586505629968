import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAuthStore } from '../store/authStore';
import { useMsjs } from '../contexts/msjsContext';


const createApi = (token: string = ''): AxiosInstance => {
    //const { setError, showToastMsj, showMessage } = useMsjs();
    const _token = useAuthStore.getState().currentUser?.api_token;
    const _logout = useAuthStore.getState().logout;
    //const authToken = getAuth();
    token = _token ? _token : token;
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
    const config: AxiosRequestConfig = {
        baseURL: API_URL,
        timeout: 600000,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    const api = axios.create(config);

    api.interceptors.request.use(
        config => {
            // Puedes modificar la configuración de la solicitud aquí
            // Por ejemplo, agregar headers como un token de autenticación
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
        error => {
            // Aquí manejas los errores que ocurren antes de que la solicitud sea enviada
            return Promise.reject(error);
        }
    );

    api.interceptors.response.use(
        (response: AxiosResponse) => {
            const { data } = response;
            if (data) {
                switch (data.statusCode) {
                    case 401:
                        _logout();
                        break;
                    case 400:

                        break;
                    default:
                        break;
                }
                //console.log('Response:', response);
            }
            return response;
        },
        (error) => {
            if (error?.response.status === 401)
                _logout();
            return Promise.reject(error);
        }
    );

    return api;
};

export default createApi;
