import {ModalComponent} from 'app/component/common/modals/modalComponent'
import {useMsjs} from 'app/contexts/msjsContext'
import {endPointsWithParams} from 'app/data/endpoints'
import {useFetchGeneric} from 'app/hooks/useRepos'
import {fetchAxios} from 'app/services'
import {useAuthStore} from 'app/store/authStore'
import {calculateCostVariation, calculateMargin} from 'app/utilities/calculate'
import {calcularFecha} from 'app/utilities/dates'
import axios from 'axios'
import clsx from 'clsx'
import {useEffect, useState} from 'react'
import {Alert, Button, Row, Col, Container} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import ExcelJS from 'exceljs'

// import * as XLSX from 'xlsx'
interface UploadVerificationProps {
  onBack: () => void
  show: boolean
  data?: any[]
  m_costlist_id?: number | undefined
}

interface Product {
  barcode: string
  brand_name: string
  code: string
  description: string
  inventory_days: number
  inventory_rotation: number
  last_cost: number
  name: string
  product_id: number
  sales_price: number
  stock: number
  uom: string
}
interface RowCheckedType {
  reason:
    | 'sku_not_found'
    | 'barcode_not_found'
    | 'cost_higher'
    | 'no_cost'
    | 'no_price'
    | 'no_variation'
    | 'no_uom'
    | 'accepted'
  index: number
  code: string
  comment?: string
  product?: Product
  newCost?: number
  margin?: number
}
interface ErrorsType {
  description?: string
  errorType: 'invalidPdfType' | 'invalidExcelType' | 'errosDoc' | 'invalidFilSize' | undefined
}

export const UploadMultiPDF: React.FC<UploadVerificationProps> = ({
  onBack,
  show,
  data,
  m_costlist_id,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [progress, setProgress] = useState(0)
  const [errors, setErrors] = useState<ErrorsType>({errorType: undefined})
  const [archivoExcel, setArchivoExcel] = useState<any>(null)
  const [modifiedExcel, setModifiedExcel] = useState<any>(null)
  const [archivoPdf, setArchivoPdf] = useState<any>(null)
  const [stepStatus, setStepStatus] = useState<number>(1)
  const [products, setProducts] = useState<Product[]>([])
  const {currentUser} = useAuthStore()
  const [rowChecked, setRowChecked] = useState<RowCheckedType[]>([])
  const navigate = useNavigate()
  const {setError, showToastMsj, showMessage} = useMsjs()
  const API_URL = process.env.REACT_APP_API_URL ?? ''

  const r_endpoint = endPointsWithParams.COSTLIST_TEMPLATE_GET
  const r_params = {...r_endpoint.params}
  const {
    data: r_data,
    error: r_error,
    isLoading: r_loading,
  } = useFetchGeneric(
    async () => {
      try {
        const {data} = await fetchAxios<any>(
          r_endpoint.url.replace('{businessPartnerId}', currentUser?.business_partner_id ?? ''),
          {}
        )
        return data
      } catch (error: any) {
        setError(error)
      }
    },
    ['getXLSTemplate'],
    currentUser
  )
  const clearError = () => setErrors({errorType: undefined, description: ''})
  const handleFileExcelChange = (files: File[]) => {
    if (files.length > 0) {
      clearError()
      setArchivoExcel(null)
      const file = files[0]
      // Validar el tipo de archivo y el tamaño
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        Swal.fire({
          icon: 'error',
          title: 'Archivo Excel inválido',
          text: 'Formato de archivo inválido',
          showConfirmButton: false,
          timer: 3000,
        })
        setErrors({errorType: 'invalidExcelType', description: 'Formato de archivo inválido'})
      } else if (file.size > 5000000) {
        Swal.fire({
          icon: 'error',
          title: 'Tamanio inválido',
          text: 'El archivo es demasiado grande',
          showConfirmButton: false,
          timer: 3000,
        })
        setErrors({errorType: 'invalidFilSize', description: 'El archivo es demasiado grande'})
      } else {
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Archivo excel adjuntado exitosamente!',
          showConfirmButton: false,
          timer: 5000,
        })
        clearError()
        setArchivoExcel(file)
      }
    } else {
      clearError()
    }
  }
  const styles = {
    columnA: {
      font: {bold: true, color: {argb: '000000'}},
    },
  }

  const handlePdfChange = (files: File[]) => {
    if (files.length > 0) {
      clearError()
      setArchivoPdf(null)
      const file = files[0]
      if (file.type !== 'application/pdf') {
        Swal.fire({
          icon: 'error',
          title: 'Archivo PDF inválido',
          text: 'Formato de archivo inválido',
          showConfirmButton: false,
          timer: 3000,
        })
        setErrors({errorType: 'invalidPdfType', description: 'Formato de archivo inválido'})
      } else if (file.size > 2000000) {
        Swal.fire({
          icon: 'error',
          title: 'Tamanio inválido',
          html: `
            El archivo es demasiado grande.
            <br />
            <br />
            El tamaño máximo permitido es de <strong>2 MB</strong>
          `,
          showConfirmButton: false,
          timer: 3000,
        })
        setErrors({errorType: 'invalidFilSize', description: 'El archivo es demasiado grande'})
      } else {
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Archivo PDF adjuntado exitosamente!',
          showConfirmButton: false,
          timer: 5000,
        })
        clearError()
        setArchivoPdf(file)
      }
    } else {
      clearError()
    }
  }

  const handleGenerateCostList = async () => {
    if (progress === 100) {
      try {
        const API_URL = process.env.REACT_APP_API_URL
        const SAVE_COST_LIST = `${API_URL}/mcostlist/saveCostlist`

        const modifiedProducts = rowChecked
          .filter((p) => p.newCost !== undefined)
          .map((product) => ({
            product_id: product.product?.product_id,
            current_cost: product.product?.last_cost.toFixed(6),
            new_cost: product?.newCost,
            current_sales_price: product.product?.sales_price,
            estimated_sales_price: product.product?.sales_price,
            margin: calculateMargin(product.product?.last_cost, product?.newCost).toFixed(6),
            cost_variation_percentage: calculateCostVariation(
              product.product?.last_cost,
              product.newCost
            ).toFixed(6),
          }))

        const payload = {
          business_partner_id: currentUser?.business_partner_id,
          m_costlist_id: m_costlist_id,
          //? Eliminar fechas (no deja guardar datos)
          costlist_pdf: archivoPdf,
          valid_since: calcularFecha(0), //startdate,
          valid_until: calcularFecha(1), // enddate,
          validity_days: 1, // validityDays,
          products: modifiedProducts,
        }

        const response = await axios.post(SAVE_COST_LIST, payload, {
          headers: {
            Authorization: `Bearer ${currentUser?.api_token}`,
            'Content-Type': 'multipart/form-data',
          },
        })

        if (response.data.success) {
          setIsCompleted(true)
          setIsLoading(false)
          // reset()
          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'La lista de costos fue generada exitosamente!',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          setIsLoading(false)
          const errorMessage = await response.data.text()
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
          })
        }
      } catch (error) {
        setIsLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error al procesar la solicitud',
        })
      }
    }
    setNullAll()
    navigate('/supplier/costlist/list')
    onBack()
  }
  const validateExcelData = async (excelFile: File) => {
    try {
      const data = await excelFile.arrayBuffer()
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(data)
      const worksheet = workbook.getWorksheet(1)
      const rowschecked: RowCheckedType[] = [] // Suponiendo que quieres la primera hoja
      if (worksheet) {
        const maxRow = worksheet.actualRowCount
        const newColumnLetter = 'F'

        // Agregar una nueva columna con datos
        //worksheet.getColumn(newColumnLetter).values = ['Observación', ...Array(maxRow).fill('-')]

        // Iterar sobre las filas y aplicar lógica
        for (let rowNumber = 4; rowNumber <= maxRow; rowNumber++) {
          // Comienza en 2 para saltar el encabezado
          const row = worksheet.getRow(rowNumber)
          const cellCode = row.getCell(1).value as string // Columna A
          const cellCost = row.getCell(5).value as number // Columna E
          const cellComment = row.getCell(6) // Columna F
          const productFound = products.find((p) => p.code === cellCode)

          if (productFound) {
            const costValue = Number(cellCost)

            switch (true) {
              case parseFloat(productFound.last_cost.toFixed(2)) ===
                parseFloat(costValue.toFixed(2)):
                rowschecked.push({
                  reason: 'no_variation',
                  index: rowNumber,
                  code: productFound.code,
                  comment: 'No hay variación',
                })
                break
              case costValue === 0:
                rowschecked.push({
                  reason: 'no_cost',
                  index: rowNumber,
                  code: productFound.code,
                  comment: 'Costo cero',
                })
                break
              case costValue >= productFound.last_cost * 2:
                rowschecked.push({
                  reason: 'cost_higher',
                  index: rowNumber,
                  code: productFound.code,
                  comment: 'Costo superior',
                })
                break

              default:
                rowschecked.push({
                  reason: 'accepted',
                  index: rowNumber,
                  code: productFound.code,
                  comment: 'Costo correcto',
                  newCost: costValue,
                  product: productFound,
                })
                break
            }
          } else {
            rowschecked.push({
              reason: 'barcode_not_found',
              index: -1,
              code: cellCode,
              comment: 'Código no encontrado',
            })
          }

          cellComment.value = rowschecked[rowschecked.length - 1].comment
          row.getCell(1).style = styles.columnA
        }

        // Aplicar estilos a la columna B
        /*  worksheet.getColumn(6).eachCell({includeEmpty: true}, (cell) => {
          cell.font = {bold: true, color: {argb: 'FFFF0000'}}
        }) */
      }

      const excelBuffer = await workbook.xlsx.writeBuffer()
      setModifiedExcel(excelBuffer)
      setRowChecked(rowschecked)
    } catch (error) {
      console.error('Error al leer el archivo Excel:', error)
    }
  }

  const downloadXLSTemplateFile = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Lista de costos')

    // Agregar datos a la hoja de trabajo
    const templateData = [
      ['Proveedor:', currentUser?.business_partner_list[0]?.name ?? ""],
      ['Listado de Productos'],
      [
        'SKU',
        'Nombre del Producto',
        'Código Barra',
        'Unidad de manejo',
        'Ultimo Costo',
        'Observación',
      ],
      ...products.map((product) => [
        product.code,
        product.description,
        product.barcode,
        product.uom,
        product.last_cost,
        '-',
      ]),
    ]

    // Agregar datos a las celdas
    templateData.forEach((row, rowIndex) => {
      worksheet.addRow(row)
    })

    worksheet.mergeCells('A2:F2')
    worksheet.mergeCells('B1:F1')

    const cellA0 = worksheet.getCell(1, 1)
    for (let col = 1; col <= 6; col++) {
      const cellA1 = worksheet.getCell(1, col)
      const cellA2 = worksheet.getCell(2, col)
      const cellA3 = worksheet.getCell(3, col)
      const headerStyles: any = {
        font: {size: 24, bold: true},
        alignment: {horizontal: 'center', vertical: 'middle'},
        fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'CCFFCC'}},
      }
      cellA0.style = {
        ...cellA1.style,
        font: {size: 12, bold: false},
        alignment: {horizontal: 'center', vertical: 'middle'},
        fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'CCFFCC'}},
      }
      cellA1.style = {
        ...cellA1.style,
        ...headerStyles,
      }
      cellA2.style = {
        ...cellA2.style,
        ...headerStyles,
      }
      cellA3.style = {
        ...cellA3.style,
        font: {size: 11, bold: true},
        alignment: {horizontal: 'center', vertical: 'middle'},
        fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: '3699FF'}},
      }
    }

    // Ajustar ancho de las columnas al contenido
    worksheet.columns = templateData[2].map((_, colIndex) => {
      const colWidth = Math.max(
        ...templateData.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0))
      )
      return {width: colWidth + 2} // Ajuste adicional
    })

    // Escribir el archivo Excel en un buffer
    const buffer = await workbook.xlsx.writeBuffer()

    // Crear un blob y descargar el archivo
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const fileURL = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = fileURL
    link.download = 'costlist_template.xlsx'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadFileRejected = async () => {
    if (!modifiedExcel) return

    try {
      // Cargar el archivo Excel desde el buffer
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(modifiedExcel)

      // Escribir el archivo Excel en un buffer
      const buffer = await workbook.xlsx.writeBuffer()

      // Crear un blob y descargar el archivo
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const fileURL = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = fileURL
      link.download = 'costlist_template_rejected.xlsx'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error al procesar el archivo Excel:', error)
    }
  }
  const handleVerifyFiles = async () => {
    setIsLoading(true)
    validateExcelData(archivoExcel)
    //TODO (hc): Simular proceso de carga (reemplazar con tu lógica real)
    for (let i = 0; i <= 100; i++) {
      await new Promise((resolve) => setTimeout(resolve, 50))
      setProgress(i)
    }
    setStepStatus(2)
    setIsLoading(false)
  }

  const Header = () => {
    return (
      <Container className='container'>
        {stepStatus === 1 && (
          <>
            <div className='row align-items-center'>
              <div className='col-3'>&nbsp;</div>
              <div className='col-1 d-flex'>
                <div className='border border-primary rounded-circle p-1'>
                  <div className='bg-primary rounded-circle p-2'>
                    <i className='bi bi-file-earmark-text fs-1 text-white'></i>
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='border border-2 border-primary rounded'></div>
              </div>
              <div className='col-2'>
                <div className='border border-2 border-gray-300 rounded'></div>
              </div>
              <div className='col-1 d-flex'>
                <div className='border border-gray-400 rounded-circle p-1'>
                  <div className='bg-gray-400 rounded-circle p-2'>
                    <div className='position-relative'>
                      <i className='bi bi-file-text fs-1 text-white'></i>
                      <i className='bi bi-plus-circle-fill fs-8 text-white position-absolute bottom-0 end-0'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-1 align-items-center'>
              <div className='col-3'>&nbsp;</div>
              <div className='col-5'>
                <span className='fs-6 text-gray-400'>Paso 1</span>
                <p className='fs-4 fw-bolder text-gray-700 m-0'>Sube el archivo</p>
              </div>
              <div className='col-3'>
                <span className='fs-6 text-gray-400'>Paso 2</span>
                <p className='fs-4 fw-bolder text-gray-700 m-0'>Verificación de la lista subida</p>
              </div>
            </div>
            <div className='row mt-2 align-items-center'>
              <div className='col-3'>&nbsp;</div>
              <div className='col-5'>
                <span className='bg-light-primary text-primary fs-7 px-4 py-2 rounded'>Actual</span>
              </div>
              <div className='col-3'>
                <span className='bg-gray-100 text-gray-500 fs-7 px-4 py-2 rounded'>Pendiente</span>
              </div>
            </div>
          </>
        )}
        {stepStatus === 2 && (
          <>
            <div className='row align-items-center'>
              <div className='col-3'>&nbsp;</div>
              <div className='col-1 d-flex'>
                <div className='border border-success rounded-circle p-1'>
                  <div className='bg-success rounded-circle p-2'>
                    <i className='bi bi-file-earmark-text fs-1 text-white'></i>
                  </div>
                </div>
              </div>
              <div className='col-2'>
                <div className='border border-2 border-success rounded'></div>
              </div>
              <div className='col-2'>
                <div className='border border-2 border-success rounded'></div>
              </div>
              <div className='col-1 d-flex'>
                <div className='border border-primary rounded-circle p-1'>
                  <div className='bg-primary rounded-circle p-2'>
                    <div className='position-relative'>
                      <i className='bi bi-file-text fs-1 text-white'></i>
                      <i className='bi bi-plus-circle-fill fs-8 text-white position-absolute bottom-0 end-0'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-1 align-items-center'>
              <div className='col-3'>&nbsp;</div>
              <div className='col-5'>
                <span className='fs-6 text-gray-400'>Paso 1</span>
                <p className='fs-4 fw-bolder text-gray-700 m-0'>Sube el archivo</p>
              </div>
              <div className='col-3'>
                <span className='fs-6 text-gray-400'>Paso 2</span>
                <p className='fs-4 fw-bolder text-gray-700 m-0'>Verificación de la lista subida</p>
              </div>
            </div>
            <div className='row mt-2 align-items-center'>
              <div className='col-3'>&nbsp;</div>
              <div className='col-5'>
                {stepStatus < 2 && (
                  <span className='bg-light-primary text-primary fs-7 px-4 py-2 rounded'>
                    Actual
                  </span>
                )}
                {stepStatus === 2 && (
                  <span className='bg-light-rv-success text-success fs-7 px-4 py-2 rounded'>
                    completado
                  </span>
                )}
              </div>
              <div className='col-3'>
                {stepStatus < 2 && (
                  <span className='bg-gray-100 text-gray-400 fs-7 px-4 py-2 rounded'>
                    Pendiente
                  </span>
                )}
                {stepStatus === 2 && (
                  <span className='bg-light-primary text-primary fs-7 px-4 py-2 rounded'>
                    En proceso
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </Container>
    )
  }

  const Footer = () => {
    const renderProgressBar = () => (
      <div className='mt-4 w-50'>
        <progress className='bg-success w-100 rounded ' value={progress} max='100'>
          {progress}%
        </progress>
        <p className='text-center mt-2'>{`Procesando...${progress}%`}</p>
      </div>
    )

    const renderContinueButton = () => (
      <Button
        variant='primary'
        onClick={handleVerifyFiles}
        className={clsx(
          'w-50 btn btn-primary',
          archivoExcel && archivoPdf ? '' : ' disabled-div',
          isLoading ? 'btn-loading' : ''
        )}
        disabled={isCompleted}
      >
        Continuar
      </Button>
    )

    const renderErrorButton = () => (
      <Button
        variant='danger'
        onClick={() => setStepStatus(1)}
        className={clsx('w-50 btn btn-primary bg-danger')}
      >
        Volver
      </Button>
    )

    const renderProcessButtons = () => (
      <>
        {rowChecked.filter((f) => f.reason === 'accepted')?.length > 0 && (
          <Button
            variant='primary'
            onClick={handleGenerateCostList}
            className={clsx('w-50 btn btn-primary', {
              'disabled-div': archivoExcel === '' || archivoPdf === '',
              'btn-loading': isLoading,
            })}
          >
            Generar Lista de costo
          </Button>
        )}

        <Button
          variant='secondary'
          onClick={() => {
            setNullAll()
            onBack()
          }}
          className={clsx('w-50 btn btn-primary text-danger')}
        >
          Cancelar
        </Button>
      </>
    )
    const renderVolver =
      errors.errorType === 'invalidExcelType' ||
      errors.errorType === 'invalidPdfType' ||
      errors.errorType === 'invalidFilSize'
    return (
      <>
        {isLoading
          ? renderProgressBar()
          : stepStatus === 1
          ? renderContinueButton()
          : renderVolver
          ? renderErrorButton()
          : renderProcessButtons() && renderProcessButtons()}
      </>
    )
  }

  const setNullAll = () => {
    setErrors((prev) => ({...prev, format: undefined}))
    setArchivoPdf(null)
    setArchivoExcel(null)
    setIsLoading(false)
    setProgress(0)
    setStepStatus(1)
  }

  /*  const downloadXLSTemplateFile = () => {
    handleDownload()
     const worksheet = XLSX.utils.json_to_sheet(products) // Crea la hoja de trabajo
    const workbook = XLSX.utils.book_new() // Crea el libro de trabajo
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Productos') // Agrega la hoja al libro

    // Genera el archivo Excel
    XLSX.writeFile(workbook, 'products.xlsx')
  } */
  useEffect(() => {
    setNullAll()
  }, [show])

  useEffect(() => {
    if (data) setProducts(data)
  }, [data, r_data])

  return (
    <div className='container mt-5'>
      <ModalComponent
        size='lg'
        header={<Header />}
        footer={<Footer />}
        show={show}
        onHide={() => {
          setNullAll()
          onBack()
        }}
      >
        <div className='container'>
          {isCompleted ? (
            <div className='text-center mt-5'>
              <Alert variant='success'>¡La lista de costos fue generada exitosamente!</Alert>
              <Button
                variant='primary'
                onClick={() =>
                  Swal.fire(
                    'Ver detalles',
                    'Esta es la información detallada de la lista de costos.',
                    'info'
                  )
                }
              >
                Ver detalles
              </Button>
            </div>
          ) : stepStatus === 1 ? (
            <div>
              <Row className='justify-content-center'>
                <Col md={4}>
                  <div className='d-flex align-items-center justify-content-center flex-column h-100 gap-4'>
                    <h5 className='card-title'>Descarga el archivo</h5>
                    <p className='card-text text-center fs-6'>
                      A continuación puedes descargar la plantilla
                    </p>
                    <Button
                      variant='outline-primary'
                      className='border-1'
                      onClick={downloadXLSTemplateFile}
                    >
                      <i className='bi bi-download me-2' />
                      Descarga la plantilla
                    </Button>
                  </div>
                </Col>
                <Col md={5}>
                  <div className='d-flex align-items-center justify-content-center flex-column h-100 gap-4'>
                    <Dropzone onDrop={handleFileExcelChange} multiple={false}>
                      {({getRootProps, getInputProps, isDragActive}) => (
                        <div
                          className='d-flex flex-column align-items-center justify-content-center gap-2'
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <Row
                              className='align-items-center justify-content-center rounded-4 p-3 border-success'
                              style={{border: '4px dotted'}}
                            >
                              <Col md={2}>
                                <i className='bi bi-upload me-2 fs-1' />
                              </Col>
                              <Col className='text-start' md={10}>
                                <h5>Suelta el archivo Excel!</h5>
                                <p>
                                  Arrartra y suelta el archivo que deseas cargar a la lista de
                                  costos
                                </p>
                              </Col>
                            </Row>
                          ) : (
                            <Row
                              className={`align-items-center justify-content-center rounded-4 p-3 border-gray-400 `}
                              style={{border: '4px dotted'}}
                            >
                              <Col md={2}>
                                <i className='bi bi-upload me-2 fs-1' />
                              </Col>
                              <Col className='text-start' md={10}>
                                <h5>{archivoExcel?.name || 'Cargar el Archivo'}</h5>
                                {!archivoExcel && (
                                  <p>
                                    Selecciona el archivo que haz modificado para subir a la lista
                                    de costos
                                  </p>
                                )}
                              </Col>
                            </Row>
                          )}
                        </div>
                      )}
                    </Dropzone>
                    <Dropzone onDrop={handlePdfChange} multiple={false}>
                      {({getRootProps, getInputProps, isDragActive}) => (
                        <div
                          className='d-flex flex-column align-items-center justify-content-center gap-2'
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <Row
                              className='align-items-center justify-content-center rounded-4 p-3 border-danger'
                              style={{border: '4px dotted'}}
                            >
                              <Col md={2}>
                                <i className='bi bi-upload me-2 fs-1' />
                              </Col>
                              <Col className='text-start' md={10}>
                                <h5>Suelta el archivo PDF!</h5>
                                <p>Selecciona el archivo PDF que desea asociar</p>
                              </Col>
                            </Row>
                          ) : (
                            <Row
                              className={`align-items-center justify-content-center rounded-4 p-3 border-gray-400 `}
                              style={{border: '4px dotted'}}
                            >
                              <Col md={2}>
                                <i className='bi bi-file-pdf me-2 fs-1' />
                              </Col>
                              <Col className='text-start' md={10}>
                                <h5>{archivoPdf?.name || 'Cargar el PDF'}</h5>
                                {!archivoPdf && <p>Selecciona el archivo PDF que desea asociar</p>}
                              </Col>
                            </Row>
                          )}
                        </div>
                      )}
                    </Dropzone>
                    {errors.errorType && (
                      <Alert variant='danger' className='mt-2'>
                        {errors.description}
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ) : stepStatus === 2 ? (
            <div>
              <Row className='justify-content-center'>
                <Col className='d-flex justify-content-start' md={6}>
                  <div className='d-flex flex-column gap-4'>
                    {rowChecked.filter((f) => f.reason === 'no_variation')?.length > 0 && (
                      <h5 className='d-flex flex-row gap-4'>
                        <i className={clsx('bi fs-3 me-2 bi-slash-circle text-danger')}></i>
                        <span>
                          {`Sin variación (${
                            rowChecked.filter((f) => f.reason === 'no_variation')?.length
                          })`}
                        </span>
                      </h5>
                    )}
                    {rowChecked.filter((f) => f.reason === 'barcode_not_found')?.length > 0 && (
                      <h5 className='d-flex flex-row gap-4'>
                        <i className={clsx('bi fs-3 me-2 bi-slash-circle text-danger')}></i>
                        <span>
                          {`Productos no encontrados (${
                            rowChecked.filter((f) => f.reason === 'barcode_not_found')?.length
                          })`}
                        </span>
                      </h5>
                    )}
                    {rowChecked.filter((f) => f.reason === 'cost_higher')?.length > 0 && (
                      <h5 className='d-flex flex-row gap-4'>
                        <i className={clsx('bi fs-3 me-2 bi-slash-circle text-danger')}></i>
                        <span>
                          {`Productos con costo superior (${
                            rowChecked.filter((f) => f.reason === 'cost_higher')?.length
                          })`}
                        </span>
                      </h5>
                    )}
                    {rowChecked.filter((f) => f.reason !== 'accepted')?.length > 0 && (
                      <div className='d-flex flex-row gap-4'>
                        <h5 className='d-flex flex-row gap-4'>
                          <i className={clsx('bi fs-3 me-2 bi-slash-circle text-danger')}></i>
                          <span>
                            {`Productos rechazados (${
                              rowChecked.filter((f) => f.reason !== 'accepted')?.length
                            })`}
                          </span>
                        </h5>
                      </div>
                    )}

                    <h5 className='d-flex flex-row gap-4'>
                      <i className={clsx('bi fs-3 me-2 bi-check-circle text-success')}></i>
                      <span>
                        {`Productos aprobados (${
                          rowChecked.filter((f) => f.reason === 'accepted')?.length
                        })`}
                      </span>
                    </h5>
                  </div>
                </Col>
                {rowChecked.filter((f) => f.reason !== 'accepted')?.length > 0 && (
                  <div className='d-flex justify-content-center'>
                    <Button 
                      className='p-3 fs-4 btn-outline-gray-300 w-50 text-primary'
                      variant='outline-secondary'
                      onClick={downloadFileRejected} 
                    >
                      <i className='bi bi-cloud-download fs-1 text-primary px-4'></i>
                     <span>Descargar verificación</span> 
                    </Button>
                  </div>
                )}
              </Row>
            </div>
          ) : (
            ''
          )}
        </div>
      </ModalComponent>
    </div>
  )
}
