import {useQuery} from '@tanstack/react-query'
import {
  CostlistDetailParams,
  CostlistEventlogParams,
  CostlistListParams,
  NewCostlistParams,
} from '../../types/fetch/costlist/fetchParams'
import {
  getCostlistDetail,
  getCostlistEventLog,
  getCostlistList,
  getNewCostlist,
} from '../../services/supplier/costlist'
import {dateToString} from '../../utilities/dates'

export function useCostlistListQuery(
  currentUser: any,
  dates: {start: Date | undefined; end: Date | undefined} = {start: undefined, end: undefined}
) {
  return useQuery<any[], Error>({
    queryKey: ['getCostlistList', currentUser, dates],
    queryFn: async () => {
      let params: CostlistListParams = {business_partner_id: currentUser?.business_partner_id ?? ''}
      if (dates.start) params.start_date = dateToString(dates.start)
      if (dates.end) params.start_date = dateToString(dates.end)
      const {data} = await getCostlistList(params)
      return data
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
    enabled: !!currentUser,
  })
}

export function useCostlistDetailQuery(business_partner_id: any, m_costlist_id: number) {
  return useQuery<any[], Error>({
    queryKey: ['getCostlistDetail', business_partner_id, m_costlist_id],
    queryFn: async () => {
      const params: CostlistDetailParams = {
         business_partner_id  ,
        m_costlist_id: m_costlist_id ?? 0,
      }
      const {data} = await getCostlistDetail(params)
      return data
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
    enabled: true,
  })
}

export function useCostlistEventlogQuery(
  m_costlist_id: number,
  dates: {start: Date | undefined; end: Date | undefined} = {start: undefined, end: undefined},
  total_rows: number | undefined = undefined
) {
  return useQuery<any[], Error>({
    queryKey: ['getCostlistEventLog', m_costlist_id, dates, total_rows],
    queryFn: async () => {
      const params: CostlistEventlogParams = {m_costlist_id: m_costlist_id ?? 0}
      if (dates.start) params.start_date = dateToString(dates.start)
      if (dates.end) params.start_date = dateToString(dates.end)
      if (total_rows) params.total_rows = total_rows ?? 5
      const {data} = await getCostlistEventLog(params)
      return data
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
    enabled: !!m_costlist_id,
  })
}

export function useNewCostlist(currentUser: any) {
  return useQuery<any[], Error>({
    queryKey: ['getCostlistEventLog', currentUser],
    queryFn: async () => {
      let params: NewCostlistParams = {business_partner_id: currentUser.business_partner_id}
      const data = await getNewCostlist(params)
      return data
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
    enabled: !!currentUser,
  })
}
