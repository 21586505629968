import {Container, Modal, ModalProps, Row} from 'react-bootstrap'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {title} from 'process'
import clsx from 'clsx'

interface Props extends ModalProps {
  id?: any
  children: any
  backDrop?: boolean
  header?: string | JSX.Element
  footer?: string | JSX.Element
  subtitle?: string
  show: boolean
  onHide: () => void
  className?: string
}
const ModalComponent = (props: Props) => {
  const {children, id, backDrop, header, subtitle, show, onHide, footer, className, ...rest} = props
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby='contained-modal-title-vcenter'
      centered={rest.centered ?? true}
      backdrop={backDrop ?? 'static'}
      className={clsx('bg-transparent', className)}
      size={rest.size ?? 'lg'}
      rest
    >
      <div className='modal-content bg-gray-200'>
        <div className='modal-header border-0 d-flex justify-content-between align-items-start'>
          <div className='d-flex justify-content-center w-100'>{header}</div>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={onHide}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </button>
          {subtitle}
        </div>
        <div className='modal-body m-2 p-1'>
          <Container>{children}</Container>
        </div>
        <div className='modal-footer d-flex justify-content-center w-100 p-4'>{footer}</div>
      </div>
    </Modal>
  )
}
export {ModalComponent}
