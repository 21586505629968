/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import './SellProduct.scss'
import product from '../../../img/sin-foto-prv.png'
import axios from 'axios'
import {useAuth} from '../../../auth'
import {formatNumber} from '../../../../utilities/_format'
import {useAuthStore} from '../../../../store/authStore'

const API_URL = process.env.REACT_APP_API_URL
const VENDORS_URL = `${API_URL}/business_partner/product_sales_by_date_range`

const SellProduct = () => {
  const [data, setData] = useState([])
  //const {currentUser} = useAuth()
  const {saveAuth, currentUser, userCan, userHas, userLandingRoute, auth, setCurrentUser, logout} =
    useAuthStore()
  const token = currentUser?.api_token

  useEffect(() => {
    if (token) {
      const endDate = new Date()
      const startDate = new Date(new Date().setDate(endDate.getDate() - 30))
      axios
        .get(VENDORS_URL, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: {
            business_partner_id: currentUser?.business_partner_id,
            start_date: startDate.toISOString().split('T')[0],
            end_date: endDate.toISOString().split('T')[0],
          },
        })
        .then((response) => {
          setData(response.data.data)
        })
        .catch((error) => console.error('Error al obtener datos de la API', error))
    }
  }, [token, currentUser])
  return (
    <div className='sellproduct__card card p-10'>
      <div className='overflow-auto'>
        <h1 className='text-primary fs-2'>Ventas por productos - Último Mes</h1>
        <div className='card-body'>
          {data &&
            data.map((sale: any, index) => (
              <div key={index} className='d-flex align-items-center mb-8'>
                <span className='bullet bullet-vertical h-40px '></span>
                <div className='sellproduct__form form-check form-check-custom form-check-solid mx-5'>
                  <img src={product} alt='productImg' className='w-50px' />
                </div>
                <div className='flex-grow-1 fw-bold fs-5'>
                  {sale.name}

                  <span className='text-muted fw-semibold d-block'>Total : {sale.total}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default SellProduct
