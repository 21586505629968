/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useEffect, useState} from 'react'
import {useBusinessPartnerProductListQuery} from 'app/hooks/query/useBusinessPartnerProduct'
import {useNewCostlist} from 'app/hooks/query/useCoslits'
import {UploadMultiPDF} from 'app/modules/supplier/costlist/components/partials/modals/CargaMasiva'
import {hasAccessToRoute, navigateToRouteIfAllowed} from 'app/utilities/roles'
import { useAuthStore } from 'app/store/authStore'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const [showModal, setShowModal] = useState(false)
  const {currentUser} = useAuthStore()
  const [costList, setCostList] = useState<any>(undefined)
  const {data: dataL} = useBusinessPartnerProductListQuery(currentUser)
  const {data: cl_data, error: cl_error} = useNewCostlist(currentUser)

  useEffect(() => {
    if (cl_data?.['data']) setCostList(cl_data?.['data']?.['costlist'])
    else setCostList(undefined)
  }, [cl_data, cl_error, dataL])
  return (
    <>
      {hasAccessToRoute('/dashboard') && (
        <SidebarMenuItem
          to={navigateToRouteIfAllowed('/dashboard')}
          icon='element-11'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      )}
      {hasAccessToRoute('/supplier/product') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/product')}
          title='Productos'
          fontIcon='bi-tagfill'
          icon='abstract-2'
        >
          {hasAccessToRoute('/supplier/product/list') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/product/list')}
              title='Inventario de Productos'
              icon='data'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {hasAccessToRoute('/supplier/sales') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/sales')}
          title='Ventas por tienda'
          fontIcon='bi-tagfill'
          icon='abstract-12'
        >
          {hasAccessToRoute('/supplier/sales/overview') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/sales/overview')}
              title='Detalle General'
              icon='data'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {hasAccessToRoute('/supplier/salescuts') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/salescuts')}
          title='Cortes de Venta'
          fontIcon='bi-tagfill'
          icon='bill'
        >
          {hasAccessToRoute('/supplier/salescuts/list') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/salescuts/list')}
              title='Lista de Cortes de Venta'
              icon='bill'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {hasAccessToRoute('/supplier/sales/discount') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/sales/discount')}
          title='Promociones'
          fontIcon='bi-tagfill'
          icon='percentage'
        >
          {hasAccessToRoute('/supplier/sales/scalediscount') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/sales/scalediscount')}
              title='Generación de promociones'
              icon='percentage'
              hasBullet={true}
            />
          )}
          {hasAccessToRoute('/supplier/sales/historicdiscount') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/sales/historicdiscount')}
              title='Histórico de promociones'
              icon='percentage'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {hasAccessToRoute('/supplier/corder') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/corder')}
          title='Órdenes de pedido'
          fontIcon='bi-tagfill'
          icon='package'
        >
          {hasAccessToRoute('/supplier/corder/create') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/corder/create')}
              title='Generación de órdenes'
              icon='package'
              hasBullet={true}
            />
          )}
          {hasAccessToRoute('/supplier/corder/list') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/corder/list')}
              title='Histórico de órdenes'
              icon='package'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {hasAccessToRoute('/supplier/verifier') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/verifier')}
          title='Aprobador'
          fontIcon='bi-tagfill'
          icon='check'
        >
          {hasAccessToRoute('/supplier/verifier') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/verifier')}
              title='Revisión de listas de costos'
              icon='check'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {hasAccessToRoute('/supplier/agreements') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/agreements')}
          title='Notas de Crédito'
          fontIcon='bi-tagfill'
          icon='abstract-14'
        >
          {hasAccessToRoute('/supplier/agreements') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/agreements')}
              title='Agregar notas de crédito'
              icon='check'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {hasAccessToRoute('/supplier/costlist') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/costlist')}
          title='Lista de Costos'
          fontIcon='bi-tagfill'
          icon='element-1'
        >
          {hasAccessToRoute('/supplier/costlist/add') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/costlist/add')}
              title='Generación por carga masiva'
              icon='add-item'
              hasBullet={true}
              onClick={() => {
                if (!costList) return
                setShowModal(true)
              }}
            />
          )}
          {hasAccessToRoute('/supplier/costlist/add') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/costlist/add')}
              title='Generación de Lista de Costos'
              icon='add-item'
              hasBullet={true}
            />
          )}
          {hasAccessToRoute('/supplier/costlist/list') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/costlist/list')}
              title='Histórico'
              icon='data'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {/*       {(userHas(currentUser, 'Purchase Settler') || process.env.NODE_ENV !== 'production') && (
 )} */}
      {hasAccessToRoute('/supplier/sales/checkcut') && (
        <SidebarMenuItemWithSub
          to={navigateToRouteIfAllowed('/supplier/sales/checkcut')}
          title='Liquidador'
          fontIcon='bi-tagfill'
          icon='pencil'
        >
          {hasAccessToRoute('/supplier/sales/check') && (
            <SidebarMenuItem
              to={navigateToRouteIfAllowed('/supplier/sales/check')}
              title='Revisión de cortes de ventas'
              icon='pencil'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      <UploadMultiPDF
        data={dataL}
        show={showModal}
        m_costlist_id={costList?.m_costlist_id}
        onBack={() => {
          /* onChange() */
          setShowModal(false)
        }}
      />
    </>
  )
}

export {SidebarMenuMain}
