import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useAuth } from '../../auth'
import { useAuthStore } from '../../../store/authStore'

const Error404: FC = () => {
  //const {currentUser, userLandingRoute} = useAuth()
  const { saveAuth,currentUser, userCan, userHas, userLandingRoute,auth, setCurrentUser, logout } = useAuthStore(); 
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Lo Sentimos!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>El recurso que estas solicitando no ha podido ser encontrado.</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to={userLandingRoute(currentUser)} className='btn btn-sm btn-primary'>
          Volver al Inicio
        </Link>
      </div>
      {/* end::Link */}
    </>
  )
}

export {Error404}
