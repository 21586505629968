const toastColors = {
    'error': '#EEE5FF',
    'info': '#E1F0FF',
    'success': '#C9F7F5',
    'warning': '#FFF4DE',
    'warn': '#FA8072'
  };
  const alertIcons = {
    'danger': 'abstract-11',
    'info': 'information-2',
    'success': 'like',
    'warning': 'notification'
  };
  export { toastColors, alertIcons}