import useCurrencyStore from '../store/currencyStore'
import {MonedasType} from '../types'
import {currencyFormats} from './currencyFormats'

const isNumeric = (value: any) => {
  return /^-?\d*\.?\d+$/.test(value)
}

const isValidDate = (value: any) => {
  return !isNaN(new Date(value).getTime())
}

const formatNumberWithCurrency = (number: number, decimales: number = 2): string => {
  const currencyFormat = useCurrencyStore.getState().getCurrencyFormats()
  if (isNaN(number)) {
    console.error('El valor proporcionado no es numérico')
    return '0.00'
  }
  const numberFormat = {
    style: currencyFormat.numberFormat.decimal,
    minimumFractionDigits: decimales,
    maximumFractionDigits: currencyFormat.maximumFractionDigits,
  }
  return new Intl.NumberFormat(undefined, numberFormat).format(number)
}

const formatDateWithCurrency = (date: Date): string => {
  const currencyFormat = useCurrencyStore.getState().getCurrencyFormats()
  return date.toLocaleDateString(undefined, currencyFormat.dateFormat)
}

const formatCurrencyWithCurrency = (
  amount: number,
  moneda: MonedasType,
  convert: boolean = false
): string => {
  const ccurrency = useCurrencyStore.getState().currentCurrency
  if (isNaN(amount)) {
    console.error('El valor proporcionado no es numérico', amount)
    return '0.00'
  }
  let result: string
  if (!convert) {
    result = new Intl.NumberFormat(
      currencyFormats[moneda].locale,
      currencyFormats[moneda].numberFormat
    ).format(amount)
  } else {
    switch (moneda) {
      case 'VES':
        result =
          ccurrency === moneda
            ? new Intl.NumberFormat(
                currencyFormats[ccurrency].locale,
                currencyFormats[ccurrency].numberFormat
              ).format(amount)
            : new Intl.NumberFormat(undefined, currencyFormats[ccurrency].numberFormat).format(
                amount / currencyFormats[ccurrency].tasaCambio
              )
        break
      case 'USD':
      case 'EUR':
        result =
          ccurrency === moneda
            ? new Intl.NumberFormat(
                currencyFormats['VES'].locale,
                currencyFormats['VES'].numberFormat
              ).format(amount * currencyFormats[moneda].tasaCambio)
            : new Intl.NumberFormat(
                currencyFormats[moneda].locale,
                currencyFormats[moneda].numberFormat
              ).format(amount)
        break
      default:
        throw new Error('Moneda no válida')
    }
  }
  return result.replace('USD', '$ ').replace('Bs.S', 'Bs.')
}

const formatValue = (
  type: 'date' | 'number' | 'currency',
  value: any,
  moneda: MonedasType = 'USD',
  convert: boolean = false,
  decimals: number = 2
): string => {
  switch (type) {
    case 'number':
      if (!isNumeric(value)) {
        console.error('El valor proporcionado no es numérico')
        //throw new Error('El valor proporcionado no es numérico.')
      }
      return formatNumberWithCurrency(value)
    case 'date':
      if (!isValidDate(value)) {
        throw new Error('El valor proporcionado no es una fecha válida.')
      }
      return formatDateWithCurrency(value)
    case 'currency':
      return formatCurrencyWithCurrency(value, moneda, convert)
    default:
      throw new Error('Tipo de dato no válido.')
  }
}
const formatBs = (value, convert = true) => {
  return formatValue('currency', value, 'VES', convert)
}
const formatUSD = (value, convert = true) => {
  return formatValue('currency', value, 'USD', convert)
}
const getFormatAmountBs = (value, multiplo, convert = true) => {
  const amount = value * Number(multiplo)
  return formatValue('currency', amount, 'VES', convert)
}
const getFormatAmountUSD = (value, multiplo, convert = true) => {
  const amount = value * Number(multiplo)
  return formatValue('currency', amount, 'USD', convert)
}
export {formatValue, getFormatAmountBs, getFormatAmountUSD, formatBs, formatUSD}
