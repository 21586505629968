import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useAuthStore} from '../../../../app/store/authStore'
import useCurrencyStore from '../../../../app/store/currencyStore'
import Rlogo from 'app/modules/svg/r-redvital.png'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuthStore()
  const {changeCurrency, currentCurrency} = useCurrencyStore()
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* //TODO: Activar selector de monedas */}
      {/* <div className='py-2'>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id='flexSwitchDefault'
              checked={currentCurrency === 'USD'? false:true }
              onChange={()=>{changeCurrency(currentCurrency === 'USD'?'VES':'USD')}}
            />
            <label className='form-check-label'>{currentCurrency === 'USD'?'Dólares':'Bolívares'}</label>
          </div>
        </div> */}
      {/* //TODO: Activar Search global */}
      {/*<div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
  </div>*/}
      {/*<div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
  </div>*/}
      {/*<div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
</div>*/}
      {/*<div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
</div>*/}
      {/*<div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
</div>*/}
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col text-end'>
                <span className='text-primary fs-5 fw-bolder mt-2'>{currentUser?.name}</span>
                <p className='fs-5 fw-bold'>{currentUser?.occupation}</p>
              </div>
              <div className={clsx('col-auto cursor-pointer symbol', userAvatarClass)}>
                <img src={Rlogo} alt='Logo' />
              </div>
            </div>
          </div>
        </div>
        <HeaderUserMenu />
      </div>
      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
