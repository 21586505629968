import { ENDPOINTS } from '../../../data/endpoints'
import { CostlistDetailParams, CostlistEventlogParams, CostlistListParams, NewCostlistParams } from '../../../types/fetch/costlist/fetchParams'
import { fetchAxios } from '../../index'

export const getCostlistList = async ( params: CostlistListParams): Promise<any> => {
    const endpoint = ENDPOINTS.COSTLIST.LIST
    return fetchAxios<any>(endpoint, { params })
}
export const getCostlistDetail = async ( params: CostlistDetailParams): Promise<any> => {
    const endpoint = ENDPOINTS.COSTLIST.DETAIL
    return fetchAxios<any>(endpoint, { params })
}
export const getCostlistEventLog = async ( params: CostlistEventlogParams): Promise<any> => {
    const endpoint = ENDPOINTS.EVENT_LOG.BY_COSTLIST
    return fetchAxios<any>(endpoint, { params })
}

export const getNewCostlist = async ( params: NewCostlistParams): Promise<any> => {
    const endpoint = ENDPOINTS.COSTLIST.NEW
    return fetchAxios<any>(endpoint, { params })
}
 
