import React, {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../_metronic/helpers'
import swal from 'sweetalert2'
import {ToastOptions, toast} from 'react-toastify'
import {toastColors} from '../data'

// Define el tipo para el contexto de errores
interface toastType {
  msj: string
  type: 'success' | 'info' | 'error' | 'warning' | 'warn'
}
interface errInfo extends Error {
  token: string
  user: string
  browser: string
  device: string
  ipClient: string
}

interface MsjsContextType {
  error: Error | null | errInfo
  setError: (error: Error | null | errInfo) => void
  showToastMsj: (msj: string, type: 'success' | 'info' | 'error' | 'warning' | 'warn') => void
  showMessage: (
    msj: string,
    type: 'success' | 'info' | 'error' | 'warning' | 'warn',
    title?: string
  ) => void
}

// Crea el contexto de errores
const MsjsContext = createContext<MsjsContextType>({
  error: null,
  setError: () => {},
  showToastMsj: () => {},
  showMessage: () => {},
})

// Proveedor del contexto de errores
const MsjsProvider: FC<WithChildren> = ({children}) => {
  const [error, setError] = useState<Error | null | errInfo>(null)

  const showMessage = (
    msj: string,
    type: 'success' | 'info' | 'error' | 'warning' | 'warn',
    title: string = ''
  ) => {
    let swalConfig: any
    switch (type) {
      case 'success':
        swalConfig = {
          icon: 'success',
          title: title,
          text: msj,
          showConfirmButton: true,
          customClass: {
            popup: 'swal-success',
            title: 'swal-success-title',
            content: 'swal-success-content',
          },
        }
        break
      case 'info':
        swalConfig = {
          icon: 'info',
          title: title,
          text: msj,
          showConfirmButton: true,
          customClass: {
            popup: 'swal-info',
            title: 'swal-info-title',
            content: 'swal-info-content',
          },
        }
        break
      case 'error':
        swalConfig = {
          icon: 'error',
          title: title,
          text: msj,
          showConfirmButton: true,
          customClass: {
            popup: 'swal-error',
            title: 'swal-error-title',
            content: 'swal-error-content',
          },
        }
        break
      case 'warn':
      case 'warning':
        swalConfig = {
          icon: 'warning',
          title: title,
          text: msj,
          showConfirmButton: true,
          customClass: {
            popup: 'swal-warning',
            title: 'swal-warning-title',
            content: 'swal-warning-content',
          },
        }
        break

      default:
        swalConfig = {
          icon: 'info',
          title: title,
          text: msj,
          showConfirmButton: true,
        }
        break
    }

    // Mostrar el mensaje de SweetAlert2 con la configuración seleccionada
    swal.fire(swalConfig).then((result) => {
      if (result.isConfirmed) {
        // Lógica adicional después de que el usuario confirma el mensaje
      }
    })
  }
  const props: ToastOptions = {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      minWidth: '400px',
      maxWidth: '800px',
      color: '#000',
      fontSize: '1rem',
    },
  }
  const showToastMsj = (msj: string, type: 'success' | 'info' | 'error' | 'warning' | 'warn') => {
    const toastFn = toast[type]
    if (toastFn) {
      let {style} = props
      style = style = {...style, backgroundColor: toastColors[type]}
      toastFn(msj, {...props, style})
    }
  }
  // Mostrar una alerta cuando se establece un error
  useEffect(() => {
    if (error) {
      // Registrar en la BD
      if (process.env.NODE_ENV === 'production') {
        console.log('Ocurrio una excepción')
        showToastMsj(error.message, 'error')
      } else {
        let err: any = error
        if (error.name === 'AxiosError') {
          error.message = `
           </br><b>${err.message}</b> 
           </br>${err.response?.data?.message ?? ''}`
        }
        swal
          .fire({
            icon: 'error',
            title: 'Error',
            html: `
            <p>${err.message}</p>
            <pre><code>${err.stack}</code></pre>`,
            showConfirmButton: true, // Mostrar el botón de "Aceptar"
          })
          .then((result) => {
            if (result.isConfirmed) {
            }
          })
      }
    }
  }, [error])

  return (
    <MsjsContext.Provider value={{error, setError, showToastMsj, showMessage}}>
      {children}
    </MsjsContext.Provider>
  )
}

// Hook para usar el contexto de errores
const useMsjs = (): MsjsContextType => useContext(MsjsContext)

export {MsjsProvider, useMsjs}
