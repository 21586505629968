// Formatos para USD (Dólar estadounidense)
export interface CurrencyFormat {
    currency: string;
    currencySign: string;
    dateFormat: string;
    locale: string;
    numberFormat: {
        currencyDisplay: string, // Muestra el símbolo de la moneda, e.g., Bs.
        currency?: string,
        style: string;
        minimumFractionDigits: number;
        maximumFractionDigits: number;
    };
    tasaCambio:number;
}

const usdFormats: CurrencyFormat = {
    currency: 'USD',
    currencySign: '$',
    dateFormat: 'MM/dd/yyyy',
    locale: 'en-US',
    numberFormat: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol', //($)
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    },
    tasaCambio:37,
};
const euroFormats: CurrencyFormat = {
    currency: 'EUR',
    currencySign: '€',
    dateFormat: 'dd/MM/yyyy', // Cambiado al formato de fecha europeo
    locale:'es-Es',
    numberFormat: {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol', //(€)
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    },
    tasaCambio:39
  };
// Formatos para VES (Bolívar venezolano)
const vesFormats: CurrencyFormat = {
    currency: 'VES',
    currencySign: 'Bs.',
    dateFormat: 'dd/MM/yyyy',
    locale:'es-VE',
    numberFormat: {
        style: 'currency',
        currency: 'VES',
        currencyDisplay: 'symbol', // Bs.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    },
    tasaCambio:1
};

const currencyFormats = {
    USD: usdFormats,
    VES: vesFormats,
    EUR: euroFormats,
};


export { currencyFormats };