import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {formatCurrency, formatDate} from '../../../../../utilities/_format'
import {isArrayNotEmpty} from '../../../../../utilities'
import {formatUSD} from '../../../../../utilities/formatUtilities'
import { TooltipComponent } from 'app/component/common/tooltip/tooltip'

interface DataProps {
  data: any
}

const LastSaleCut: FC<DataProps> = (props) => {
  const navigate = useNavigate()

  const navigateToDetail = (object_id: string) => {
    navigate('/supplier/salescuts/detail', {
      state: {
        sale_cut_id: object_id,
      },
    })
  }

  return (
    <>
      {/*begin:component*/}
      <div className='card bg-primary text-white'>
        <div className='card-body d-flex flex-column'>
          <h1 className='text-white fw-bolder'>Último Corte de Venta</h1>
          <p className='text-white fw-normal fs-4'>Fecha: {formatDate(props.data.document_date)}</p>
          <div className='text-white d-flex flex-row justify-content-between'>
            <p className='text-white fs-3 fw-bold'>Total:</p>
            <span className='text-white fs-1 fw-bolder'>{formatUSD(props.data.total)}</span>
          </div>
          <TooltipComponent id= 'gotod' tooltip={'Ir a detalle'}>
          <button
            className='btn btn-light mt-3'
            style={{
              color: '#1E40AF',
              fontSize: '1.5rem',
              backgroundColor: '#e1f0ff',
              fontWeight: '600',
            }}
            onClick={() => navigateToDetail(props.data.sale_cut_id)}
          >
            Detalles
          </button>

          </TooltipComponent>
        </div>
      </div>
    </>
  )
}

export {LastSaleCut}
