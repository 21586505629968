import {FC} from 'react'
import './ActionButton.scss'

type Props = {
  image: string
  title: string
  backgroundColor: string
}

const ActionButton: FC<Props> = ({image, title, backgroundColor}) => {
  const cardStyle = {
    backgroundColor: backgroundColor,
  }

  return (
    <div className='actionbtn__card card' style={cardStyle}>
      <div className='actionbtn__body card-body d-flex flex-column'>
        <img src={image} alt='ActionButtonImg' />
        <h1>{title}</h1>
      </div>
    </div>
  )
}

export {ActionButton}
