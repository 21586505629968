/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './ActivitiesToDo.scss'

const ActivitiesToDo = () => {
  return (
    <div className='activitiestodo__card card'>
      <div className='card-header border-0'>
        <h1 className='text-primary'>Actividades por realizar</h1>
      </div>
      <div className='card-body pt-2'>
        <div className='d-flex align-items-center mb-8'>
          <span className='bullet bullet-vertical h-40px '></span>
          <div style={{marginLeft: '20px'}} className='flex-grow-1'>
            <a href='#' className=' text-hover-primary'>
              No tiene actividades por realizar
            </a>
            <span className='text-muted fw-semibold d-block'>N/A</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivitiesToDo
