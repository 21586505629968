
/**
 * Suma o resta días a una fecha, según el valor del offset proporcionado.
 * @param offset El número de días que se sumarán o restarán a la fecha.
 * @param date La fecha a la que se aplicará el offset.
 * @returns La fecha calculada en formato de cadena "YYYY-MM-DD".
 */
export function calcularFecha(offset: number, date?: Date): string {
    const fecha = new Date(date ? date : Date.now());
    fecha.setDate(fecha.getDate() + offset);
    return fecha.toISOString().split('T')[0].substring(0, 10);
}


export function dateToString(date: Date): string {
    return date.toISOString().substring(0, 10)
}