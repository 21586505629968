import {lazy, FC, Suspense, useState, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {useAuthStore} from '../store/authStore'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const ProductPage = lazy(() => import('../modules/supplier/product/ProductPage'))
  const SupplierAgreements = lazy(() => import('../modules/supplier/agreements/AgreementsPage'))
  const SalesPage = lazy(() => import('../modules/supplier/sales/SalesPage'))
  const DiscountVerification = lazy(
    () => import('../modules/supplier/discountVerification/DiscountVerificationPage')
  )
  const PromotionsApprover = lazy(
    () => import('../modules/supplier/promotionsApprover/PromotionsApprover')
  )

  const COrderPage = lazy(() => import('../modules/supplier/corder/COrderPage'))
  const SalesCutsPage = lazy(() => import('../modules/supplier/salescuts/SalesCutsPage'))
  const VerifierPage = lazy(() => import('../modules/supplier/verifier/VerifierPage'))
  const CostlistPage = lazy(() => import('../modules/supplier/costlist/CostlistPage'))

  const {currentUser, userHas, userLandingRoute, logout} = useAuthStore()
  let [landingRoute, setLandingRoute] = useState('/auth')

  useEffect(() => {
    if (currentUser && currentUser?.rolesNames?.length > 0) {
      setLandingRoute(userLandingRoute(currentUser))
    } else {
      logout()
    }
  }, [currentUser, logout, userLandingRoute])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={landingRoute} />} />
        {/* Pages */}
        {userHas(currentUser, 'Supplier') ? (
          <Route path='dashboard' element={<DashboardWrapper />} />
        ) : (
          ''
        )}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/agreements/*'
          element={
            <SuspensedView>
              <SupplierAgreements />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/product/*'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/sales/*'
          element={
            <SuspensedView>
              <SalesPage />
            </SuspensedView>
          }
        />

        <Route
          path='supplier/salescuts/*'
          element={
            <SuspensedView>
              <SalesCutsPage />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/verifier/*'
          element={
            <SuspensedView>
              <VerifierPage />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/agreements/*'
          element={
            <SuspensedView>
              <SupplierAgreements />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/promotionsapprover/*'
          element={
            <SuspensedView>
              <PromotionsApprover />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/discountverification/*'
          element={
            <SuspensedView>
              <DiscountVerification />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/corder/*'
          element={
            <SuspensedView>
              <COrderPage />
            </SuspensedView>
          }
        />
        <Route
          path='supplier/costlist/*'
          element={
            <SuspensedView>
              <CostlistPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
