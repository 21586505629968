import axios, { AxiosRequestConfig } from "axios";
import createApi from "../utilities/api";
import { useAuthStore } from "../store/authStore";


const fetchAxios = async <T>(endpoint: string, config: AxiosRequestConfig = {}): Promise<T> => {
    const { method = 'GET', params, data } = config;
    const api = createApi();
    const requestConfig: AxiosRequestConfig = {
        ...config,
        method: method.toLowerCase(),
        url: endpoint,
        params: method === 'GET' ? params : undefined,
        data: method === 'POST' ? data : undefined,
    };

    try {
        const response = await api.request(requestConfig);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export {  fetchAxios }