import {useMsjs} from 'app/contexts/msjsContext'
import {useAuthStore} from 'app/store/authStore'
import {useNavigate} from 'react-router-dom'

// Define roles and their associated routes
const roleRoutes: {[role: string]: string[]} = {
  Supplier: [
    '/dashboard',
    '/supplier/agreements',
    '/supplier/costlist',
    '/supplier/costlist/add',
    '/supplier/costlist/list',
    '/supplier/product',
    '/supplier/product/list',
    '/supplier/sales',
    '/supplier/sales/overview',
    '/supplier/salescuts',
    '/supplier/salescuts/list',
    /* 
      '/supplier/sales/discount', 
      '/supplier/sales/scalediscount',
      '/supplier/sales/historicdiscount',
      '/supplier/corder',
      '/supplier/corder/create',
      '/supplier/corder/list', 
      '/supplier/verifier',
      */
  ],
  'Purchase Settler': ['/supplier/sales/checkcut', '/supplier/sales/check'],
  Approver: ['/supplier/verifier'],
  Buyer: ['/supplier/costlist', '/supplier/costlist/list'],
  Manager: ['/supplier/costlist', '/supplier/costlist/list'],
  Administrator: ['/*'], // Admin has access to all routes
}
const getRol = () => {
  const currentUser = useAuthStore.getState().currentUser
  const userHas = useAuthStore.getState().userHas
  switch (true) {
    case userHas(currentUser, 'Supplier'):
      return 'Supplier'
    case userHas(currentUser, 'Purchase Settler'):
      return 'Purchase Settler'
    case userHas(currentUser, 'Approver'):
      return 'Approver'
    case userHas(currentUser, 'Buyer'):
      return 'Buyer'
    case userHas(currentUser, 'Manager'):
      return 'Manager'
    default:
      return 'Buyer'
  }
}
const hasAccessToRoute = (route: string): boolean => {
  //TODO: modo developer
  return (
    roleRoutes[getRol()]?.includes(route) || roleRoutes[getRol()]?.includes('/*') //|| process.env.NODE_ENV !== 'production'
  )
}
const navigateToRouteIfAllowed = (route: string, pathName: any = undefined) => {
  if (hasAccessToRoute(route)) {
    return route
  } else {
    console.warn('Unauthorized access!')
    if (pathName) return pathName
    return 'auth/*'
  }
}

export {hasAccessToRoute, navigateToRouteIfAllowed, getRol}
