import {FC} from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { useAuth } from '../../auth'
import { useAuthStore } from '../../../store/authStore'

const Error403: FC = () => {
  //const {logout} = useAuth()
  const { saveAuth,currentUser, userCan, userHas, userLandingRoute,auth, setCurrentUser, logout } = useAuthStore(); 
  let navigate = useNavigate()
  let handleLogout = () => {
    logout()
    navigate('/auth/login')
  }

  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Lo Sentimos!</h1>
      {/* end::Title */}

      <div className='py-4'>
        <i className="bi bi-lock-fill text-dark" style={{fontSize: "12rem"}}></i>
      </div>

      {/* begin::Text */}
      <div className='fw-semibold fs-4 text-gray-500 mb-1'>
        El Acceso a este Portal es Restringido.
      </div>
      <div className='fw-semibold fs-6 text-gray-500 mb-5'>
        Por favor contacta con el administrador si consideras que esto ha sido un error.
      </div>
      {/* end::Text */}

      

      {/* begin::Link */}
      <div className='mb-0'>
        <Button
          onClick={() => handleLogout()}>
          Iniciar Sesión
        </Button>
      </div>
      {/* end::Link */}
    </>
  )
}

export {Error403}
