/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './RecentActivities.scss'
import threeDot from '../../../img/three-dot.png'

const RecentActivities = () => {
  return (
    <div className='recentactivities__card card'>
      <div className='card-header border-0 mt-5 d-flex align-items-center'>
        <span>Mi actividad reciente</span>
        <img src={threeDot} alt='three-dot' />
      </div>
      <div className='card-body pt-2'>
        <div className='recentactivities__item d-flex align-items-center mb-8'>
          <span style={{marginRight: '4%'}} className='bullet bullet-dot w-10px h-10px'></span>
          <div className='flex-grow-1'>
            <a href='#' className=' text-hover-primary'>
              Sin actividades
            </a>
            <span style={{marginLeft: '2%'}} className='text-muted fw-semibold d-block'>
              N/A
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecentActivities
