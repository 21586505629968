// Formateo de unidades
/*const formatNumber = (number) => {
  const numStr = number.toString()
  let result = ''
  let count = 0

  for (let i = numStr?.length - 1; i >= 0; i--) {
    count++
    result = numStr[i] + result
    if (count % 3 === 0 && i !== 0) {
      result = '.' + result
    }
  }

  return result
}*/

const formatNumber = (value:number, digits:number = 2, locale:string = 'es-VE') => {
  return Intl.NumberFormat(locale, {minimumFractionDigits: digits, maximumFractionDigits: digits}).format(value)
}

// Formateo de Valores con $
const formatCurrency = (value: number) => {
  if (value === undefined || value === null) {
    return '0.00$'
  }
  return (
    value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + '$'
  )
}

// Formateo de Fecha
const formatDate = (value: string) => {
  if (value === undefined || value === null) {
    return 'dateFormatError'
  }
  return ((new Date(value+'T00:00:01')).toLocaleDateString('es-VE', {
    year: 'numeric', month: '2-digit', day: '2-digit' 
  }))
}

const formatDateDayMonthOnly = (value: string) => {
  if (value === undefined || value === null) {
    return 'dateFormatError'
  }
  return ((new Date(value)).toLocaleDateString('es-VE', {
    year: 'numeric', month: '2-digit', day: '2-digit' 
  }).slice(0,5))
}

// Fecha actual

const actualDate = () => {
  const currentDate = new Date()
  const day = ('0' + currentDate.getDate()).slice(-2)
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2)
  const year = currentDate.getFullYear()
  return `${day}/${month}/${year}`
}

export {formatNumber, formatCurrency, formatDate, formatDateDayMonthOnly, actualDate}
