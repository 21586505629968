/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState, useRef} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {} from '../../../_metronic/partials/widgets'

import {QuickAction} from '../../modules/kpi/components/DashboardPage/QuickAction'
import ActivitiesToDo from '../../modules/kpi/components/DashboardPage/ActivitiesToDo'
import SellProduct from '../../modules/kpi/components/DashboardPage/SellProduct'
import SellStore from '../../modules/kpi/components/DashboardPage/SellStore'
import {LastSaleCut} from '../../modules/supplier/salescuts/components/partials/LastSaleCut'
import {getSalesCuts} from '../../modules/supplier/salescuts/core/_requests'
import RecentActivities from '../../modules/kpi/components/DashboardPage/RecentActivities'

import axios from 'axios'
import {useAuth} from '../../modules/auth'
import { useAuthStore } from '../../store/authStore'

const API_URL = process.env.REACT_APP_API_URL
const VENDORS_URL = `${API_URL}/business_partner/product_sales_by_date_range`

interface DataProps {
  lastSaleCut: any
}

const DashboardPage: FC<DataProps> = (props) => (
  <>
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-md-5 col-lg-5 col-xl-5 col-xxl-5 mb-md-5 mb-xl-10'>
        <QuickAction className='mt-3' chartHeight='' />
        <div className='mt-3'>
          <ActivitiesToDo />
        </div>
      </div>
      <div className='col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-md-5 mb-xl-10'>
        <div className='mt-3'>
          <SellProduct />
        </div>
        <div className='mt-3'>
          <SellStore />
        </div>
      </div>
      <div className='col-xxl-3'>
        <div className='mt-3'>
          <LastSaleCut data={props.lastSaleCut} />
        </div>
        <div className='mt-3'>
          <RecentActivities />
        </div>
      </div>
      
    </div>
    
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  //const {currentUser} = useAuth()
  const { saveAuth,currentUser, userCan, userHas, userLandingRoute,auth, setCurrentUser, logout } = useAuthStore(); 
  const token = currentUser?.api_token

  const didRequest = useRef(false)
  const [lastSaleCut, setLastSaleCut] = useState([])

  useEffect(() => {
    const requestData = async (apiToken: string, business_partner_id: string) => {
      try {
        if (!didRequest.current) {
          let endDate = new Date()
          let startDate = new Date(new Date().setDate(endDate.getDate() - 365))
          const {data} = await getSalesCuts(
            '',
            startDate.toISOString().split('T')[0],
            endDate.toISOString().split('T')[0],
            null,
            apiToken,
            business_partner_id,
          )
          if (data) {
            didRequest.current = true
            if (data.data[0]) {
              setLastSaleCut(data.data[0])
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (currentUser?.api_token && currentUser?.business_partner_id) {
      requestData(currentUser?.api_token, currentUser?.business_partner_id)
    }
  }, [currentUser, setLastSaleCut])

  if (token) {
    let endDate = new Date()
    let startDate = new Date(new Date().setDate(endDate.getDate() - 30))
    axios
      .get(VENDORS_URL, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          business_partner_id: currentUser?.business_partner_id,
          start_date: startDate.toISOString().split('T')[0],
          end_date: endDate.toISOString().split('T')[0],
        },
      })
      .then((response) => {})
      .catch((error) => console.error('Error al obtener datos de la API', error))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage lastSaleCut={lastSaleCut} />
    </>
  )
}

export {DashboardWrapper}
