import {useMutation, useQuery, QueryClient} from '@tanstack/react-query'
import {useMsjs} from 'app/contexts/msjsContext'

export const useFetchGeneric = (fetchFunction, queryKey, currentUser) => {
  const {setError} = useMsjs()
  return useQuery<any[], Error>({
    queryKey: [...queryKey, currentUser],
    queryFn: async () => {
      try {
        const data = await fetchFunction()
        return data
      } catch (error: any) {
        setError(error)
      }
    },
    keepPreviousData: true,
    staleTime: 1000 * 30, // 30 segundos
    enabled: !!currentUser,
  })
}
export const useMutateGeneric = (
  mutateFunction: (params: any) => Promise<any>,
  mutateKey: string[],
  dataList = 'dataList'
) => {
  const queryClient = new QueryClient()
  return useMutation({
    mutationKey: mutateKey,
    mutationFn: async (params: any) => await mutateFunction(params),
    onMutate: async (newTodo: any) => {
      await queryClient.cancelQueries({queryKey: [...mutateKey, dataList]})
      const previousList: any = queryClient.getQueryData([...mutateKey, dataList])
      if (previousList) {
        queryClient.setQueryData([...mutateKey, dataList], {
          ...previousList,
          newTodo,
        })
      }
      return {previousList}
    },
    onError: (err: any, variables, context) => {
      if (context?.previousList) {
        queryClient.setQueryData([...mutateKey, dataList], context.previousList)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: [...mutateKey, dataList]})
    },
  })
}

/* Example usage of the hook using fetchAxios and sending data and files */
export const useFileMutation = (
  mutateFunction: (params: any) => Promise<any>,
  mutateKey: string[]
) => {
  const queryClient = new QueryClient()
  return useMutation({
    mutationKey: mutateKey,
    mutationFn: async (params: any) => {
      const formData = new FormData()
      Object.entries(params.data).forEach(([key, value]) => {
        formData.append(key, value as any)
      })
      Object.entries(params.files).forEach(([key, value]) => {
        if (value instanceof File) {
          formData.append(key, value)
        } else {
          formData.append(key, JSON.stringify(value))
        }
      })
      return await mutateFunction(formData)
    },
    onMutate: async (newData: any) => {
      await queryClient.cancelQueries({queryKey: mutateKey})
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: mutateKey})
    },
  })
}

/* Ejemplo de uso
const { mutate, isLoading, isError, error } = useMutateGeneric(async (id, newTodo) => {
  // Lógica para actualizar el todo en el backend
  return await fetch(`/todos/${id}`, {
    method: 'PUT',
    body: JSON.stringify(newTodo),
  })}, ['todos'])
  const handleUpdateTodo = (id, newTodo) => {
    mutate({ id, newTodo });
  };   
  
*/
