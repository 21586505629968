import axios from 'axios'

let API_URL = process.env.REACT_APP_API_URL

let GET_SALES_CUTS_URL = `${API_URL}/sale_cut/list`
let GET_SALE_CUT_DETAIL_URL = `${API_URL}/sale_cut/detail`
let CREATE_SALE_CUT_INVOICE_URL = `${API_URL}/sale_cut_document/save_invoice`
let CREATE_SALE_CUT_INVOICE_DETAIL_URL = `${API_URL}/sale_cut_document/save_invoice_detail`
let DELETE_SALE_CUT_INVOICE_URL = `${API_URL}/sale_cut_document/delete_invoice`
let UPDATE_SALE_CUT_DOCUMENT_CREDITMEMO_PDF_URL = `${API_URL}/sale_cut_document/update_creditmemo_pdf`
let COMPLETE_SALE_CUT_URL = `${API_URL}/sale_cut/complete`
let GET_EXCHANGE_RATES_URL = `${API_URL}/exchange_rate/getRatesByExchangeIndicatorAndDateRange`
let GET_SALE_CUT_DOCUMENT_EVENT_LOG_URL = `${API_URL}/event_log/getEventLogBySaleCutDocument`
let UPDATE_SALE_CUT_DOCUMENT_INVOICE_PDF_URL = `${API_URL}/sale_cut_document/update_invoice_pdf`
let UPDATE_SALE_CUT_INVOICE_URL = `${API_URL}/sale_cut_document/update_invoice`

export function getSalesCuts(
  query_search: string,
  start_date: string,
  end_date: string,
  status_code: any, 
  token: string,
  business_partner_id?: string,
) {
  return axios.get<any>(GET_SALES_CUTS_URL,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        business_partner_id,
        query_search,
        start_date,
        end_date, 
        status_code 
      }
    })
}

export function getSaleCutDetail(
  token: string,
  sale_cut_id: string,
) {
  return axios.get<any>(GET_SALE_CUT_DETAIL_URL,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        sale_cut_id
      }
    })
}

export function newInvoice(
  formData: FormData,
  token: string,
  ) {
  let config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(CREATE_SALE_CUT_INVOICE_URL, formData, config)
}

export function saveInvoiceDetail(
  formData: FormData,
  token: string,
  ) {
  let config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(CREATE_SALE_CUT_INVOICE_DETAIL_URL, formData, config)
}

export function deleteInvoice(
  formData: FormData,
  token: string,
  ) {
  let config = {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(DELETE_SALE_CUT_INVOICE_URL, formData, config)
}

export function updateCreditMemoPDF(
  formData: FormData,
  token: string,
  ) {
  let config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(UPDATE_SALE_CUT_DOCUMENT_CREDITMEMO_PDF_URL, formData, config)
}

export function completeSaleCut(
  formData: FormData,
  token: string,
  ) {
  let config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(COMPLETE_SALE_CUT_URL, formData, config)
}

export function getExchangeRates(
  exchange_indicator_symbol: string,
  currency_symbol_from: string,
  currency_symbol_to: string,
  start_date_range: string,
  end_date_range: string, 
  token: string
) {
  return axios.get<any>(GET_EXCHANGE_RATES_URL,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        exchange_indicator_symbol,
        currency_symbol_from,
        currency_symbol_to,
        start_date_range, 
        end_date_range 
      }
    })
}

export function getEventLogBySaleCutDocument(
  sale_cut_document_id: string,
  token: string
) {
  return axios.get<any>(GET_SALE_CUT_DOCUMENT_EVENT_LOG_URL,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        sale_cut_document_id
      }
    })
}

export function updateInvoicePDF(
  formData: FormData,
  token: string,
  ) {
  let config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(UPDATE_SALE_CUT_DOCUMENT_INVOICE_PDF_URL, formData, config)
}

export function updateInvoice(
  formData: FormData,
  token: string,
  ) {
  let config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(UPDATE_SALE_CUT_INVOICE_URL, formData, config)
}

