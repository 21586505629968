/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {ActionButton} from './ActionButton'
import barSell from '../../../img/ventas-tienda.png'
import sellCut from '../../../img/corte-de-ventas.png'
import productList from '../../../img/listado-productos.png'
import sellProduct from '../../../img/ventas-productos.png'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  chartHeight: string
}

const QuickAction: React.FC<Props> = ({className, chartHeight}) => {
  return (
    <div
      className={`card ${className}`}
      style={{background: 'linear-gradient(to bottom, #1e40af 30%, #fff 30%'}}
    >
      <div className='card-header border-0 py-5'>
        <h3 style={{fontSize: '2rem', color: '#fff'}} className='card-title fw-bold mb-10 mt-10'>
          Acciones Rápidas
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          ></button>
        </div>
      </div>
      <div className='card-body p-0'>
        <div className='mixed-widget-2-chart card-rounded-bottom'></div>
        <div className='card-p mt-n20 position-relative'>
          <div className='row g-0'>
            <div className=' col px-6 py-8 rounded-2 '>
              <Link to='/supplier/sales/overview'>
                <ActionButton image={barSell} backgroundColor='#FFF4DE' title='Ventas por tienda' />
              </Link>
            </div>

            <div className=' col px-6 py-8 rounded-2'>
              <Link to='/supplier/salescuts/list'>
                <ActionButton image={sellCut} backgroundColor='#EEE5FF' title='Cortes de Ventas' />
              </Link>
            </div>
          </div>
          <div className='row g-0'>
            <div className=' col px-6 py-8 rounded-2 mb-4'>
              <Link to='/supplier/product/list'>
                <ActionButton
                  image={productList}
                  backgroundColor='#E1F0FF'
                  title='Lista de Productos'
                />
              </Link>
            </div>
            <div className=' col px-6 py-8 rounded-2 m4-7'>
              <Link to='/supplier/sales/historic'>
                <ActionButton
                  image={sellProduct}
                  backgroundColor='#C9F7F5'
                  title='Listas de Costos'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {QuickAction}
