import { useAuthStore } from "./app/store/authStore";
import Swal from 'sweetalert2';

let idleTime = 0;
let idleTimer: NodeJS.Timeout | null = null;
let logoutTimer: NodeJS.Timeout | null = null;

const resetIdle = () => {
    idleTime = 0;
    if (idleTimer) {
        clearTimeout(idleTimer);
        idleTimer = null;
    }
    if (logoutTimer) {
        clearTimeout(logoutTimer);
        logoutTimer = null;
    }
};

const detectIdle = () => {
    resetIdle();
    const token = useAuthStore.getState().auth?.api_token;
    if (token) {
        idleTimer = setTimeout(() => {
            logoutTimer = setTimeout(() => {
                logout();
            }, 5000);
            Swal.fire({
                title: 'Su sesión está a punto de expirar.',
                text: '¿Desea continuar su sesión?',
                timer: 5000,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showCancelButton: true,
                cancelButtonText: 'Cerrar sesión'
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer || !result.value) {
                    logout(); // Cerrar sesión si el temporizador llega a cero o si el usuario cierra la alerta
                } else {
                    detectIdle(); // Reiniciar la detección de inactividad si el usuario elige continuar la sesión
                }
            });
        }, 1000*60*60*4);
    }
};

const logout = () => {
    const logout = useAuthStore.getState().logout;
    if (process.env.NODE_ENV === 'production') {
        logout();
    } else {
        Swal.fire({
            title: 'Hi dev!😎',
            timer: 1000*15,
            showConfirmButton: false
        });
        detectIdle()
    }
    // Lógica para cerrar sesión
    console.log('Sesión expirada, cerrando sesión...');
};

export { detectIdle, resetIdle };