import { useQuery } from "@tanstack/react-query";
import { dateToString } from "../../utilities/dates";
import { getBusinessPartnerProductList } from "app/services/supplier/business_partner_product";
import { BusinessPartnerProductListParams } from "app/types/fetch/business_partner_product/fetchParams";

export function useBusinessPartnerProductListQuery(currentUser: any, product_id: number | undefined = undefined, dates: { start: Date | undefined, end: Date | undefined } = { start: undefined, end: undefined }) {
  return useQuery<any[], Error>({
    queryKey: ['businessPartnerProductList',currentUser,product_id,dates],
    queryFn: async () => {
      let params: BusinessPartnerProductListParams = {business_partner_id: currentUser?.business_partner_id ?? ''}
      if (product_id) params.product_id = product_id
      if (dates.start) params.start_date = dateToString(dates.start)
      if (dates.end) params.start_date = dateToString(dates.end)
      const { data } = await getBusinessPartnerProductList(params)
      return data;
    },
    keepPreviousData: true,
    staleTime: 60 * 1000,
    enabled: !!currentUser,
  });
}
